const getAppKey = () => {
  return "3352125c5ebd4b96406861034d0ef17f";
};

const getSessionHeaders = (contentType = "application/json") => {
  return {
    "Content-Type": contentType
  };
};

export const BASE_URL = process.env.NODE_ENV === 'production'?"https://corsproxy.io/?https://www.vendus.pt/ws/v1.1":'';

export async function getData<T>(
  request: string,
  asParams: boolean
): Promise<T> {
  let endpoint: string;

  if (asParams) {
    endpoint = `${BASE_URL}${request}&api_key=${getAppKey()}`;
  } else {
    endpoint = `${BASE_URL}${request}?api_key=${getAppKey()}`;
  }

  const response = await fetch(endpoint, {
    method: "GET",
    headers: getSessionHeaders()
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return (await response.json()) as Promise<T>;
}
