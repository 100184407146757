import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { FacebookOutlined, Instagram, LinkedIn } from "@mui/icons-material";

function Footer() {
  const FONT_SIZE = 18;

  return (
    <AppBar
      position="static"
      style={{ background: "#f6f8f9", boxShadow: "none" }}
    >
      <Grid container spacing={2} sx={{ padding: 5, textAlign: "left" }}>
        <Grid item md={3} xs={12}>
          <Typography color="#808080" sx={{ fontFamily: "Avenir Black" }}>
            <FacebookOutlined /> <Instagram /> <LinkedIn />
          </Typography>
          <Typography color="#808080" sx={{ fontFamily: "Avenir Black" }}>
            Condeixa-a-Nova
          </Typography>
          <Typography color="#808080" sx={{ fontFamily: "Avenir Light" }}>
            Rua das Escola Nº54 - Arrifana <br />
            3150-252 Ega <br />
            910 095 691
          </Typography>
        </Grid>
        <Grid item md={3} xs={12}>
          <Typography
            color="#4d4d4d"
            sx={{ fontFamily: "Avenir Black", fontSize: FONT_SIZE }}
          >
            Andi
          </Typography>
          <Typography color="#808080" sx={{ fontFamily: "Avenir Light" }}>
            Sobre
          </Typography>
          <Typography
            color="#4d4d4d"
            sx={{ fontFamily: "Avenir Black", fontSize: FONT_SIZE }}
          >
            Tecnologia
          </Typography>
          <Typography color="#808080" sx={{ fontFamily: "Avenir Light" }}>
            Smartphones
          </Typography>
          <Typography color="#808080" sx={{ fontFamily: "Avenir Light" }}>
            Acessórios
          </Typography>
        </Grid>
        <Grid item md={3} xs={12}>
          <Typography
            color="#4d4d4d"
            sx={{ fontFamily: "Avenir Black", fontSize: FONT_SIZE }}
          >
            Serviços
          </Typography>
          <Typography color="#808080" sx={{ fontFamily: "Avenir Light" }}>
            Reparações
          </Typography>
          <Typography color="#808080" sx={{ fontFamily: "Avenir Light" }}>
            Suporte Informático
          </Typography>
          <Typography color="#808080" sx={{ fontFamily: "Avenir Light" }}>
            Comércio Tecnológico
          </Typography>
        </Grid>

        <Grid item md={3} xs={12}>
          <Typography
            color="#4d4d4d"
            sx={{ fontFamily: "Avenir Black", fontSize: FONT_SIZE }}
          >
            Dúvidas?
          </Typography>
          <Typography color="#808080" sx={{ fontFamily: "Avenir Light" }}>
            <a href="#">geral@andi-tec.com</a>
          </Typography>
        </Grid>
      </Grid>
    </AppBar>
  );
}
export default Footer;
