import React from "react";
import PhoneValue from "../components/PhoneValue/PhoneValue";
import Products from "../components/Products/Products";
import SlideShow from "../components/SlideShow/SlideShow";
import { CategoryType } from "../models/Global";

interface HomeProps {
  selectProduct: (id: number) => void;
}

function Home(props: HomeProps) {
  const { selectProduct } = props;
  return (
    <div>
      <SlideShow />
      <Products
        showTitle={true}
        showFilters={false}
        showPagination={false}
        title={"Últimos Modelos"}
        maxProducts={4}
        category={CategoryType.SMARTPHONE}
        selectProduct={selectProduct}
      />
      <PhoneValue />
    </div>
  );
}

// <a href="https://br.freepik.com/fotos-gratis/foto-ao-ar-livre-de-uma-mulher-bonita-focada-na-tela-do-smartphone-le-noticias-ou-digita-mensagens-de-texto-usa-jaqueta_20744548.htm#query=iphone&position=34&from_view=search&track=sph">Imagem de wayhomestudio</a> no Freepik

export default Home;
