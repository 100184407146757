export const enum Page {
  HOME = 1,
  IPHONES = 2,
  ACCESSORIES = 3,
  REPAIRS = 4,
  RETAKES = 5,
  CONTACTS = 6,
}

export enum CategoryType {
  ACCESSORIES = 122604499,
  ADAPTORS = 122662506,
  STORAGE = 122719364,
  HEADPHONES = 122719215,
  CABLES = 122662531,
  CHARGERS = 122662902,
  COMPONENTS = 122662573,
  GENERIC = 121700293,
  PARTS = 121700216,
  PERIFERICOS = 122662610,
  NETWORK = 122662752,
  REPAIR_SERVICE = 121700580,
  SMARTHOME = 122719328,
  SMARTPHONE = 120226347,
  OTHERS = 122719454,
}
