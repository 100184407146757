import { Grid } from "@mui/material";
import React from "react";
import Products from "../components/Products/Products";
import { CategoryType } from "../models/Global";

interface SmartPhonesProps {
  selectProduct: (id: number) => void;
}

function SmartPhones(props: SmartPhonesProps) {
  const { selectProduct } = props;
  return (
    <Grid container spacing={2} sx={{ paddingTop: 2 }}>
      <Grid item xs={12}>
        <Products
          showTitle={true}
          showFilters={false}
          showPagination={true}
          title={"iPhones"}
          maxProducts={20}
          category={CategoryType.SMARTPHONE}
          selectProduct={selectProduct}
        />
      </Grid>
    </Grid>
  );
}

export default SmartPhones;
