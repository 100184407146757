import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Dispatch, SetStateAction } from "react";
import { Page } from "../../models/Global";
import { Link } from "react-router-dom";

type PageValue = {
  id: string;
  value: string | number;
};

const pages: PageValue[] = [
  { id: "/", value: "Home" },
  { id: "smartphones", value: "iPhones" },
  //{ id: "parts", value: "Peças" },
  //{ id: "capas", value: "Capas" },
  // { id: "accessories", value: "Acessórios" },
  //{ id: "repairs", value: "Reparações" },
  // { id: "retakes", value: "Retomas" },
  //{ id: "contacts", value: "Contactos" },
];

type TopBarProps = {
  changePage: Dispatch<SetStateAction<Page>>;
};

function TopBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  var imageBasePath =
    window.location.protocol + "//" + window.location.host + "/";

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const floatMenu = () => {
    return (
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
        }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {pages.map((page) => (
            <MenuItem
              key={page.value}
              onClick={() => {
                handleCloseNavMenu();
                // changePage(page.id);
              }}
            >
              <Link to={page.id} style={{ textDecoration: "none" }}>
                <Typography textAlign="center">{page.value}</Typography>
              </Link>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  const standardMenu = () => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          left: 150,
          display: { xs: "none", md: "flex" },
          position: "absolute",
          height: "100%",
        }}
      >
        {pages.map((page) => (
          <Link to={page.id} style={{ textDecoration: "none" }}>
            <Button
              key={page.value}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                padding: "0px 20px 0px 20px",
                textAlign: "center",
                height: "100%",
                marginTop: 0,
                ":hover": { color: "#808080" },
              }}
            >
              {page.value}
            </Button>
          </Link>
        ))}
      </Box>
    );
  };

  return (
    <AppBar
      position="static"
      style={{ background: "#000000", boxShadow: "none" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {floatMenu()}

          <Container
            sx={{
              width: { md: 100 },
              top: { md: 10, xs: 0 },
              left: { md: 50, xs: 0 },
              position: { md: "absolute", xs: "relative" },
              height: "100%",
            }}
          >
            <Link to={"/"}>
              <img
                src={imageBasePath + "logo.svg"}
                alt="Andi Logo"
                style={{ width: 60 }}
              />
            </Link>
          </Container>
          <Container
            sx={{
              width: { xs: 48, md: 0 },
              display: { xs: "flex", md: "none" },
            }}
          />

          {standardMenu()}

          {/* User Settings are disable for now */}
          {/* {User()} */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopBar;
