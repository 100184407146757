import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { getData } from "../../utils/network";

import "./PhoneValue.css";
import { Model, Retake } from "../../models/Retakes";

function PhoneValue() {
  const [models, setModels] = useState<Model[]>([]);
  const [selectedModel, setSelectedModel] = useState<number | null>(null);
  const [selectedCapacity, setSelectedCapacity] = useState<number | null>(null);
  const [selectedGrade, setSelectedGrade] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const options = await getData<Retake>("models/retakes.json", false);
      setModels(options.models);
    };

    fetchData().catch(console.error);
  }, []);

  const introduction = () => {
    return (
      <Grid container spacing={2} sx={{ textAlign: "left" }}>
        <Grid item md={7} xs={12}>
          <Typography
            color="#4a60cc"
            sx={{ fontFamily: "Avenir Light", fontSize: { xs: 40, md: 50 } }}
          >
            O TEU SMARTPHONE
          </Typography>
          <Typography
            color="#4a60cc"
            sx={{
              fontFamily: "Avenir Black",
              fontWeight: 1000,
              fontSize: { xs: 40, md: 50 },
            }}
          >
            TEM VALOR!
          </Typography>
        </Grid>
        <Grid item md={5} xs={12}>
          <Typography
            color="#4a60cc"
            sx={{
              fontFamily: "Avenir Light",
              fontSize: 20,
              paddingTop: { xs: 2, md: 2 },
            }}
          >
            Queres comprar um telemóvel novo, mas precisa de se desfazer do seu
            equipamento antigo?<br></br> O smartphone que já não usas têm valor,
            damo-lhe uma nova vida!
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const evaluation = () => {
    return (
      <Grid container spacing={2} sx={{ paddingTop: 5, textAlign: "left" }}>
        <Grid item md={4} xs={12}>
          <Typography
            color="#4a60cc"
            sx={{ fontFamily: "Avenir Black", fontSize: 30 }}
          >
            Modelo
          </Typography>
          <Typography
            color="#4a60cc"
            sx={{ fontFamily: "Avenir Light", fontSize: 17, paddingBottom: 2 }}
          >
            Seleciona o modelo.
            <br></br>
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-autowidth-label">
              Modelo
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Modelo"
              onChange={(event) => {
                setSelectedModel(Number(event.target.value));
                setSelectedCapacity(null);
                setSelectedGrade(null);
              }}
            >
              {models.map((model, index) => {
                return <MenuItem value={index}>{model.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography
            color="#4a60cc"
            sx={{ fontFamily: "Avenir Black", fontSize: 30 }}
          >
            Capacidade
            <Typography
              color="#4a60cc"
              sx={{
                fontFamily: "Avenir Light",
                fontSize: 17,
                paddingBottom: 2,
              }}
            >
              Seleciona a capacidade do teu telemóvel.
            </Typography>
          </Typography>
          <FormControl fullWidth disabled={selectedModel === null}>
            <InputLabel id="demo-simple-select-label">Capacidade</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Capacidade"
              onChange={(event) => {
                setSelectedCapacity(Number(event.target.value));
                setSelectedGrade(null);
              }}
            >
              {selectedModel !== null && models[selectedModel].capacity ? (
                models[selectedModel].capacity.map((capacity, index) => {
                  if (capacity) {
                    return <MenuItem value={index}>{capacity.value}</MenuItem>;
                  }
                  return <></>;
                })
              ) : (
                <></>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography
            color="#4a60cc"
            sx={{ fontFamily: "Avenir Black", fontSize: 30 }}
          >
            Estado
          </Typography>
          <Typography
            color="#4a60cc"
            sx={{ fontFamily: "Avenir Light", fontSize: 17, paddingBottom: 2 }}
          >
            Em que estado se encontra o teu telemóvel?
          </Typography>
          <FormControl fullWidth disabled={selectedCapacity === null}>
            <InputLabel id="demo-simple-select-label">Estado</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Estado"
              onChange={(event) => setSelectedGrade(Number(event.target.value))}
            >
              {selectedModel !== null &&
              selectedCapacity !== null &&
              models[selectedModel].capacity ? (
                models[selectedModel].capacity[selectedCapacity].grade.map(
                  (grade, index) => {
                    if (grade) {
                      return <MenuItem value={index}>{grade.value}</MenuItem>;
                    }
                    return <></>;
                  }
                )
              ) : (
                <></>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const result = () => {
    return (
      <Grid container spacing={2} sx={{ padding: 5 }}>
        <Grid item md={12} xs={12}>
          <Typography
            color="#4a60cc"
            sx={{ fontFamily: "Avenir Light", fontSize: 20 }}
          >
            O teu Smartphone tem o valor de:
            {selectedModel !== null &&
            selectedCapacity !== null &&
            selectedGrade !== null ? (
              <div className="finalValue">
                {
                  models[selectedModel].capacity[selectedCapacity].grade[
                    selectedGrade
                  ].price
                }
                €
              </div>
            ) : (
              <p>- Preenche o Formulário Acima -</p>
            )}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography
            color="#4a60cc"
            sx={{ fontFamily: "Avenir Light", fontSize: 15 }}
          >
            *Os valores descritos para retomas poderão sofrer alterações
            <br></br>
            conforme a avaliação do técnico em Loja.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box sx={{ padding: { xs: 5, md: 0 }, paddingTop: { xs: 2, md: 2 } }}>
      {introduction()}
      {evaluation()}
      {result()}
    </Box>
  );
}

export default PhoneValue;
