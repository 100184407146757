import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getData } from "../../utils/network";
import { Brand, Part, Plataform } from "../../models/Parts";
import { Category, Search } from "@mui/icons-material";

interface FiltersProps {
  onChangeQuery: (search: string) => void;
}

function Filters(props: FiltersProps) {
  const { onChangeQuery } = props;
  const [searchType, setSearchType] = useState<string>("filter");
  const [options, setOptions] = useState<Part>();
  const [brand, setBrand] = useState<Brand>();
  const [platform, setPlatform] = useState<Plataform>();
  const [model, setModel] = useState<string>();
  const imageBasePath =
    window.location.protocol + "//" + window.location.host + "/";

  useEffect(() => {
    const fetchData = async () => {
      const options = await getData<Part>("models/parts.json", false);
      setOptions(options);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    onChangeQuery(model ?? "");
  }, [model, onChangeQuery]);

  const toggleFilterOptions = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setSearchType(newAlignment ?? "search");
    setBrand(undefined);
    setPlatform(undefined);
    setModel(undefined);
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={2} xs={12}>
        <ToggleButtonGroup
          value={searchType}
          exclusive
          onChange={toggleFilterOptions}
          aria-label="text alignment"
        >
          <ToggleButton value="filter" aria-label="filter">
            <Category />
          </ToggleButton>
          <ToggleButton value="search" aria-label="search">
            <Search />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      {searchType === "filter" ? (
        <>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              {options ? (
                <Autocomplete
                  id="brand-select-demo"
                  options={options?.brands}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setBrand(newValue as Brand);
                    if (!newValue) {
                      setPlatform(undefined);
                      setModel(undefined);
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`${imageBasePath}imgs/brands/${option.code}.png`}
                        src={`${imageBasePath}imgs/brands/${option.code}.png`}
                        alt=""
                      />
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Marca"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              ) : (
                <></>
              )}
            </FormControl>
          </Grid>

          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              {brand ? (
                <Autocomplete
                  id="brand-select-demo"
                  options={brand.plataforms}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setPlatform(newValue as Plataform);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Plataforma"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              ) : (
                <></>
              )}
            </FormControl>
          </Grid>

          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              {platform ? (
                <Autocomplete
                  id="brand-select-demo"
                  options={platform.models}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    setModel(newValue ?? "");
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Modelo"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              ) : (
                <></>
              )}
            </FormControl>
          </Grid>
        </>
      ) : (
        <Grid item md={10} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Procurar
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              value={model}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setModel(event.target.value);
              }}
              endAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
}

export default Filters;
