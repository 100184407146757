import React, { useState, useCallback } from "react";
import "./App.css";
import TopBar from "./components/TopBar/TopBar";
import Home from "./pages/Home";
import SmartPhones from "./pages/SmartPhones";
import Accessories from "./pages/Accessories";
import Repairs from "./pages/Repairs";
import Retakes from "./pages/Retakes";
import Contacts from "./pages/Contacts";
import Footer from "./components/Footer/Footer";
import { Grid } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Covers from "./pages/Covers";
import ProductPreview from "./components/Products/ProductPreview";
import Parts from "./pages/Parts";

function App() {
  const [selectedProductId, setSelectedProductId] = useState<number>(0);
  const [selectedProductPath, setSelectedProductPath] = useState<string>("");
  const navigate = useNavigate();

  const selectProduct = useCallback(
    (id: number) => {
      setSelectedProductId(id);
      setSelectedProductPath(`/productPreview?id=${selectedProductId}`);
      navigate(`/productPreview/${id}`);
    },
    [selectedProductId]
  );

  return (
    <div className="App">
      <TopBar />
      <Grid sx={{ width: { xs: "100%", md: "70%" }, margin: "auto" }}>
        <Routes>
          <Route path="/" element={<Home selectProduct={selectProduct} />} />
          <Route path="/*" element={<Home selectProduct={selectProduct} />} />
          <Route
            path="smartphones"
            element={<SmartPhones selectProduct={selectProduct} />}
          />
          <Route
            path="parts"
            element={<Parts selectProduct={selectProduct} />}
          />
          {/* <Route
            path="capas"
            element={<Covers selectProduct={selectProduct} />}
          />
          <Route
            path="accessories"
            element={<Accessories selectProduct={selectProduct} />}
          />
          <Route path="repairs" element={<Repairs />} />
          <Route path="retakes" element={<Retakes />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="productPreview/:id" element={<ProductPreview />} /> */}
        </Routes>
      </Grid>

      <Footer />
    </div>
  );
}

export default App;
