import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Slide } from "react-slideshow-image";
import "./SlideShow.css";

function SlideShow() {
  const slideImages = [
    {
      md: "imgs/woman-phone-wide.jpg",
      xs: "imgs/woman-phone.jpg",
      caption: (
        <h1 style={{ color: "#ffffff", paddingTop: 150 }}>
          Temos um smartphone <br></br> à tua medida.
        </h1>
      ),
    },
    {
      md: "imgs/man-broken-wide.jpg",
      xs: "imgs/man-broken.jpg",
      caption: (
        <h1 style={{ color: "#ffffff" }}>
          Reparações na hora <br></br> Check-up Gratuito <br></br> Orçamento
          Gratuito
        </h1>
      ),
    },
    {
      md: "imgs/woman-covers-wide.jpg",
      xs: "imgs/woman-covers.jpg",
      caption: <h1>Encontra capas entre outros acessórios.</h1>,
    },
  ];

  const buttonStyle = {
    width: "30px",
    background: "none",
    border: "0px",
  };

  const properties = {
    prevArrow: <button style={{ ...buttonStyle }}></button>,
    nextArrow: <button style={{ ...buttonStyle }}></button>,
  };

  return (
    // <Slide {...properties}>
    //   {slideImages.map((slideImage, index) => (
    //     <div key={index}>
    //       <Grid
    //         container
    //         sx={{
    //           height: {
    //             xs: 350,
    //             sm: 200,
    //             md: 350,
    //           },
    //           backgroundImage: {
    //             xs: `url(${slideImage.xs})`,
    //             sm: `url(${slideImage.md})`,
    //             md: `url(${slideImage.md})`,
    //           },
    //           backgroundSize: {
    //             xs: "390px",
    //             sm: "100%",
    //             md: "100%",
    //           },
    //         }}
    //       >
    //         <Grid
    //           container
    //           spacing={2}
    //           sx={{
    //             paddingTop: 2,
    //             paddingBottom: 2,
    //             marginTop: 2,
    //           }}
    //         >
    //           <Grid item md={7} xs={12}></Grid>
    //           <Grid item md={5} xs={12}>
    //             {slideImage.caption}
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       {/* <img className="image-size" src={slideImage.url} />
    //         <span>{slideImage.caption}</span> */}
    //     </div>
    //   ))}
    // </Slide>
    <div style={{ position: "relative", height: 400 }}>
      <Box
        component="img"
        sx={{
          height: { xs: 400, md: 360 },
          objectFit: "cover",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
        src="imgs/woman-phone-oem.jpg"
      />
      <Typography
        sx={{
          width: { md: 500, xs: 370 },
          color: "#ffffff",
          position: "absolute",
          zIndex: 10,
          fontFamily: "Avenir Light",
          fontSize: 25,
          top: { md: 200, xs: 300 },
          left: { md: "60%", xs: "0%" },
        }}
      >
        TEMOS UM SMARTPHONE <br></br> À TUA MEDIDA.
      </Typography>
    </div>
  );
}

// <a href="https://br.freepik.com/fotos-gratis/foto-ao-ar-livre-de-uma-mulher-bonita-focada-na-tela-do-smartphone-le-noticias-ou-digita-mensagens-de-texto-usa-jaqueta_20744548.htm#query=iphone&position=34&from_view=search&track=sph">Imagem de wayhomestudio</a> no Freepik
// <a href="https://br.freepik.com/fotos-gratis/jovem-encaracolado-sentado-no-chao-enviando-uma-mensagem-isolada-na-parede-branca_8472556.htm#query=iphone&position=49&from_view=search&track=sph">Imagem de diana.grytsku</a> no Freepik
// Imagem de <a href="https://br.freepik.com/fotos-gratis/pessoas-conectadas-nas-redes-sociais_20145129.htm#query=iphone%20cover&position=5&from_view=search&track=ais">Freepik</a>
// Imagem de <a href="https://br.freepik.com/fotos-gratis/retrato-de-um-jovem-chocado-mostrando-seu-smartphone-quebrado_25272086.htm#query=broken%20iphone%20screen&position=2&from_view=search&track=ais">Freepik</a>
export default SlideShow;
