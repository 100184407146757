import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getData } from "../../utils/network";
import { ProductModel } from "../../models/Product";

import "./Products.css";
import { ProductProps } from "../../models/ProductsModels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import Filters from "../Filters/Filters";

const MAX_ITEMS_PER_PAGE = 12;

function Products(props: ProductProps) {
  const {
    showTitle,
    showFilters,
    showPagination,
    title,
    maxProducts,
    category,
    selectProduct,
  } = props;
  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const endpoint = `/products/?category_id=${category}&q=${query}&per_page=${MAX_ITEMS_PER_PAGE}&page=${page}`;
  const [products, setProducts] = useState<ProductModel[]>([]);
  const imageBasePath =
    window.location.protocol + "//" + window.location.host + "/";

  const onChangeQuery = (search: string) => {
    setQuery(search);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  useEffect(() => {
    if (endpoint !== undefined) {
      const fetchData = async () => {
        const options = await getData<ProductModel[]>(endpoint, true);

        setProducts(options);
      };
      setProducts([]);

      fetchData().catch(console.error);
    }
  }, [endpoint]);

  const reorderProducts = (
    category: number,
    brand?: number
  ): ProductModel[] => {
    const filteredProducts: ProductModel[] = [];

    products.map((product) => {
      if (brand) {
        if (product.category_id === category && product.brand_id === brand) {
          filteredProducts.push(product);
        }
      } else {
        if (product.category_id === category) {
          filteredProducts.push(product);
        }
      }
    });

    filteredProducts.sort((n1, n2) => {
      if (n1.title > n2.title) {
        return 1;
      }

      if (n1.title < n2.title) {
        return -1;
      }

      return 0;
    });

    return filteredProducts;
  };

  const productList = (
    maxProducts: number,
    category: number,
    brand?: number
  ) => {
    let count = 0;

    return reorderProducts(category, brand).map((product, index) => {
      if (product.category_id === category && count < maxProducts) {
        count++;
        return (
          <Grid item md={3} xs={12}>
            <Button
              sx={{
                "&:hover": {
                  opacity: [0.9, 0.8, 0.7],
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                selectProduct(product.id);
              }}
            >
              <Box>
                <div style={{ position: "relative", top: 0, left: 0 }}>
                  <img
                    alt={product.id.toString()}
                    src={product.images.m}
                    style={{ position: "relative", top: 0, left: 0 }}
                  />
                  <img
                    alt={product.brand_id.toString()}
                    src={`${imageBasePath}imgs/brands/${product.brand_id}.png`}
                    width="32px"
                    style={{ position: "absolute", bottom: 0, left: 0 }}
                  />
                </div>

                <Typography
                  color="#0a0d0f"
                  sx={{
                    fontFamily: "Avenir Light",
                    fontWeight: "bold",
                    paddingBottom: 1,
                  }}
                >
                  {product.title.split("-")[0]}
                </Typography>

                <Typography
                  color="#0a0d0f"
                  sx={{
                    fontFamily: "Avenir Light",
                    fontSize: 12,
                    paddingBottom: 1,
                  }}
                >
                  {product.title.split("-").pop()}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  {product.barcode ? (
                    <>
                      {product.barcode}
                      <FontAwesomeIcon
                        icon={faBarcode}
                        style={{ paddingLeft: 3 }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <br />
                  {product.prices[0].price}€
                  <br />
                  {product.stock > 0 ? (
                    <>
                      <span className="dot-green" />
                      Disponível
                    </>
                  ) : (
                    <>
                      <span className="dot-yellow" />
                      Disponível por Encomenda
                    </>
                  )}
                </Typography>
              </Box>
            </Button>
            {/* {count !== 4 ? (
                <Divider
                  orientation="vertical"
                  sx={{
                    marginTop: { md: "-240px", xs: "0px" },
                    borderColor: { md: "#4a60cc", xs: "#ffffff" },
                  }}
                />
              ) : (
                <></>
              )} */}
          </Grid>
        );
      }
    });
  };

  return (
    <Box
      sx={{
        padding: { xs: 5, md: 0 },
        paddingTop: { xs: 2, md: 0 },
        paddingBottom: { md: 10 },
      }}
    >
      {showTitle ? (
        <Typography
          color="#4a60cc"
          sx={{
            fontFamily: "Avenir Light",
            fontSize: 30,
            textAlign: "left",
            paddingBottom: 3,
          }}
        >
          {title}
        </Typography>
      ) : (
        <></>
      )}
      {showFilters ? <Filters onChangeQuery={onChangeQuery} /> : <></>}
      <Grid container spacing={2}>
        {productList(maxProducts, category)}
      </Grid>
      {showPagination ? (
        <>
          <Button onClick={previousPage}> Previous </Button> |
          <Button onClick={nextPage}> Next </Button>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}

export default Products;
